/* Resume.css */
:root {
  --background-color-light: #f0f0f0;
  --text-color-light: #333333;
  --title-section-color-light: #333333;
  --link-color-light: #0066cc;
  --link-hover-color-light: #004999;

  --background-color-dark: #1c1c1c;
  --text-color-dark: #ffffff;
  --title-section-color-dark: #ffffff;
  --link-color-dark: #66b2ff;
  --link-hover-color-dark: #3399ff;

  --paper-background-light: #ffffff;
  --paper-background-dark: #2a2a2a;

  --box-shadow-light: black;
  --box-shadow-dark: white;
}


[data-theme="light"] {
  --background-color: #f0f0f0;
  --paper-background: #ffffff;
  --box-shadow: rgba(0, 0, 0, 0.1);
}

/* Dark Theme */
[data-theme="dark"] {
  --background-color: #f0f0f0;
  --paper-background: #2a2a2a;
  --box-shadow: grey;
}

/* Apply theme variables based on the body class */
body.light-theme {
  --background-color: var(--background-color-light);
  --text-color: var(--text-color-light);
  --title-section-color: var(--title-section-color-light);
  --link-color: var(--link-color-light);
  --link-hover-color: var(--link-hover-color-light);
  --paper-background: var(--paper-background-light);
  --box-shadow: var(--box-shadow-light);
}

body.dark-theme {
  --background-color: var(--background-color-dark);
  --text-color: var(--text-color-dark);
  --title-section-color: var(--title-section-color-dark);
  --link-color: var(--link-color-dark);
  --link-hover-color: var(--link-hover-color-dark);
  --paper-background: var(--paper-background-dark);
  --box-shadow: var(--box-shadow-dark);
}
/* Define light and dark theme variables */
body {
  background-color: var(--background-color);
  color: var(--text-color);
  font-family: 'Arial', sans-serif;
}

/* Paper effect for the resume container */
.resume-container {
  max-width: 900px;
  margin: 40px auto;
  padding: 40px;
  background-color: var(--paper-background); /* Dynamic paper color */
  box-shadow: 0 10px 15px var(--box-shadow), 0 4px 6px var(--box-shadow); /* Adjust shadow based on theme */
  border-radius: 10px;
  border: 1px solid grey;
  padding: 40px;
}

/* Style adjustments for titles */
h2 {
  font-size: 24px;
  color: var(--title-section-color);
  border-bottom: 2px solid var(--title-section-color);
  padding-bottom: 10px;
  margin-bottom: 20px;
}

h3 {
  color: var(--h2-color);
}

a {
  color: var(--link-color);
  text-decoration: none;
  transition: color 0.2s ease-in-out;
}

a:hover {
  color: var(--link-hover-color);
}

/* Text styling */
p, pre, code, li {
  font-size: 16px;
  line-height: 1.6;
}

/* Section spacing */
section {
  margin-bottom: 40px;
}

/* Additional styles */
