:root {
    --background-color: #ffffff;
    --text-color: #333333;
    --heading-color: #f5f5f5;
    --section-border-color: #f5f5f5;
    --link-color: #f5f5f5;
    --title-section-color:  #333333;
    --h2-color: #8f8f8f; 
    --active-background: rgb(204, 204, 204);
    --active-text: rgb(102, 102, 102);
    --active-background-over: rgb(82, 82, 82);
  }
  [data-theme="dark"] {
    --background-color: #121212;
    --text-color: #e0e0e0;
    --heading-color: #f5f5f5;
    --section-border-color: #f5f5f5;
    --link-color: #f5f5f5;
    --title-section-color:  #fafabd;
    --h2-color: #fafae2; 
    --active-background: rgb(204, 204, 204);
    --active-text: rgb(102, 102, 102);;
  }

.article-block {
    border: 1px solid #ddd;
    margin: 20px 0;
    padding: 20px;
    background-color: var(--background-color);
  }
  
  .article-title {
    margin-bottom: 10px;
  }
  
  .article-description {
    margin-bottom: 15px;
    color: var(--text-color);
  }
  
  .article-link {
    display: flex;
    justify-content: flex-start;
  }
  
  .article-link a {
    color: #007bff;
    text-decoration: none;
  }
  
  .article-link a:hover {
    text-decoration: underline;
  }
  

  /*.filter-button {
    color: var(--text-color);
    background-color: var(--background-color);

  }*/

  .pagination button {
    border: none;
    background-color: var(--background-color);
    color: var(--text-color);
    padding: 8px 12px;
    font-size: 16px;
    cursor: pointer;
    border-radius: 4px;
    transition: background-color 0.3s, color 0.3s;
  }
  
  .pagination button:hover {
    background-color: var(--active-background-over);
    color: #fff;
  }
  
  .pagination button.active {
    background-color: var(--active-background);
    color: var(--active-text);
    font-weight: bold;
  }
  
  .pagination button:disabled {
    background-color: #cccccc;
    color: #666;
    cursor: not-allowed;
  }