/* App.css */

.app {
  text-align: center;
}

header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  background-color: var(--background-color);
  border-bottom: 1px solid var(--section-border-color);
}

h1 {
  color: var(--heading-color);
}
