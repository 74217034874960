:root {
  --photo-border-color: #ffffff;
}
[data-theme="dark"] {
  --photo-border-color: #ffffff;
}

.header {
  background-image: url('../photo/japaratinga.jpg'); /* Path to your image */
  background-size: cover; /* Ensure the image covers the entire header */
  background-position: center; /* Center the image */
  background-repeat: no-repeat; /* Prevent the image from repeating */
  color: white;
  padding: 20px;
  display: flex;
  justify-content: center; /* Center the header content horizontally */
  align-items: center;
  text-shadow: 1.5px 1.5px 1.5px rgb(0, 0, 0);
  height: 300px; /* Adjust height as needed */
}

.header-content {
  display: flex;
  align-items: center; /* Align items in the same horizontal line */
  flex-direction: column;
  width: 100%; /* Ensure full width */
  max-width: 1200px; /* Optional: Limit the maximum width for better readability */
}

.profile-container {
  display: flex;
  align-items: center; /* Vertically center the photo */
  margin-right: 20px; /* Add spacing between the photo and the navigation */
}

.profile-photo {
  width: 140px;
  height: 140px;
  background-image: url('../photo/photo.png'); /* Path to your image */
  background-size: cover;
  background-position: center;
  border-radius: 50%;
  margin-right: 40px; /* Add spacing between the photo and the navigation */
  position: relative;
}

.profile-photo::before,
.profile-photo::after {
  content: "";
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  border: 0.2rem solid var(--photo-border-color);
  border-radius: 50%;
  box-shadow: 1.5px 1.5px 1.5px rgb(0, 0, 0);;
}

.profile-photo::before {
  width: 150px;
  height: 150px;
  border-left: none;
}

.profile-photo::after {
  width: 160px;
  height: 160px;
  border-left: none;
}

nav ul {
  list-style: none;
  padding: 0;
  display: flex;
  gap: 20px; /* Horizontal spacing between navigation links */
}

nav a {
  text-decoration: none;
}

nav a:hover {
  opacity: 0.7;
}

.social-icons {
  display: flex;
  gap: 15px;
  margin-left: 30px; /* Space between social icons and the navigation */
}

.social-icon {
  font-size: 30px;
  transition: color 0.3s ease;
  box-shadow:  1.5px 1.5px 1.5px rgb(0, 0, 0);;
}

.social-icon:hover {
  opacity: 0.7;
}

/* Responsive Adjustments */
@media (max-width: 768px) {
  .header-content {
    flex-direction: column;
    align-items: center;
  }

  .profile-container {
    margin-bottom: 10px;
  }

  .social-icons {
    margin-left: 0;
    margin-top: 10px;
  }
}

@media (max-width: 480px) {
  .profile-photo {
    width: 100px;
    height: 100px;
  }

  .profile-photo::before {
    width: 110px;
    height: 110px;
  }

  .profile-photo::after {
    width: 120px;
    height: 120px;
  }

  .social-icon {
    font-size: 25px;
  }
}
