.about-section {
    padding: 40px;
   /* background-color: #f5f5f5;*/
    border-radius: 10px;
    border: 1px ;
    margin: 20px auto;
    max-width: 800px;
  }
  
  .about-section h2 {
    font-size: 2em;
    margin-bottom: 20px;
   /* color: #282c34; */
  }
  
  .about-section p {
    line-height: 1.6;
    margin-bottom: 15px;
  }
  
  #about a {
    color: #8f8f8f;
    text-decoration: none;
  }
  
  .about-section a:hover {
    text-decoration: underline;
  }


  