
  
  
  [data-theme="light"] {
    --article-title-color: #333333;
  }
  [data-theme="dark"] {
    --article-title-color: #fafabd;
  }

  
    .article-title {
    background-color: none;
    font-size: 2rem; 
    font-weight: bold;
    text-align: center;
    text-decoration: none; 
    margin: 20px 0; 
    color: var(--article-title-color);
}

.article-content {
    text-align: center; 
    line-height: 1.6; 
    margin: 0 auto; 
    padding: 0 20px;
}

.date-label {
  padding-left: 70%;
}

pre {
  white-space: pre-wrap; 
  word-wrap: break-word; 
  max-width: 100%; 
  overflow-x: auto; 
  padding: 1em; 
  font-family: 'Courier New', Courier, monospace; 
}

code {
  font-size: 1em; 
}