
:root {
    --background-color: #ffffff;
    --text-color: #333333;
    --heading-color: #f5f5f5;
    --section-border-color: #f5f5f5;
    --link-color: #f5f5f5;
    --title-section-color:  #333333;
    --h2-color: #8f8f8f; 
  }
  [data-theme="dark"] {
    --background-color: #121212;
    --text-color: #e0e0e0;
    --heading-color: #f5f5f5;
    --section-border-color: #f5f5f5;
    --link-color: #f5f5f5;
    --title-section-color:  #fafabd;
    --h2-color: #fafae2; 
  }

.theme-toggle {
    position: absolute; /* Make the button or icon position absolute */
    top: 10px; /* Adjust top position as needed */
    right: 10px; /* Adjust right position as needed */
    display: flex;
    align-items: center;
    border: none;
    background: transparent; /* No background color */
    cursor: pointer;
    padding: 8px; /* Adjust padding as needed */
    border-radius: 4px; /* Optional: rounded corners */
  }

  .theme-toggle .icon {
    font-size: calc(1em + 10px); /* Make the icon 2 pixels bigger */
  }
  
  .theme-toggle.light {
    background-color: rgb(11,11,11); /* Light background for light theme */
  }
  
  .theme-toggle.dark {
    background-color: rgb(11,11,11);  /* Dark background for dark theme */
  }
  
  .theme-toggle .icon {
    color: #000000; /* Default icon color for light mode */
  }
  
  button.theme-toggle.light .icon {
    color: #D3D3D3; /* Icon color for light mode */
  }
  
  button.theme-toggle.dark .icon {
    color: #FFFF99; /* Icon color for dark mode */
  }
  
  .theme-toggle:hover .icon {
    opacity: 0.8; /* Optional: add hover effect */
  }